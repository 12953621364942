





import MakeQuestionDrillMath from '@/components/organisms/v3/drill/MakeQuestionMath.vue'
import DrillMethod from '@/mixins/drillsv3/DrillMethod'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { ResponseProblem, ResponseStudyProblem } from '@/mixins/drillsv3/WebApi'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component({
  components: {
    MakeQuestionDrillMath
  },
})
export default class DrillAi extends Mixins(DrillTransition, DrillMethod) {

  private subjectCode = ''
  private problems: ResponseProblem[] = []
  private studyProblem: ResponseStudyProblem | undefined

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- Drill V3 mounted')

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV3/setProcessed', false)

    // 演習対象の問題を取得
    const studyProblem = await this.getStudyProblems(this.resultDrillId)
    this.studyProblem = studyProblem
    this.$store.commit('drills/setEndCondition', studyProblem.endConditions)
    this.$store.commit('drillsV3/setCurrentPage', studyProblem.currentPage)
    if (studyProblem.endConditions.left == 0) {
      await this.completeDrillApi(this.resultDrillId)
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }

    if (!studyProblem.sProblemIds?.length) {
      // 出題対象が存在しない場合は結果画面に遷移
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }
    // ページタイプ設定
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.DRILL)
    // 授業モードと授業種別を保持
    this.$store.commit('drillsV3/setClassModeCode', studyProblem.classModeCode)
    this.$store.commit('drillsV3/setClassCategoryCode', studyProblem.classCategoryCode)

    // // 現在ページをストアに保持
    this.$store.commit('drillsV3/setCurrentPage', studyProblem.currentPage)

    // 表示用の情報を取得し、ストアに保持
    const problems = await this.getProblems([studyProblem.sProblemIds])
    await this.$store.dispatch('drillsV3/setProblems', problems)
    this.problems = problems;
    this.subjectCode = this.$store.getters['drillsV3/subjectCode']

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV3/setProcessed', true)
    Vue.prototype.$loading.complete()
  }
}
